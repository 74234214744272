<script lang="ts" setup>

import { ref } from 'vue'

import RoomieDivider from '@lahaus-roomie/roomie/src/components/RoomieDivider/index.vue'
import RoomieButtonLink from '@lahaus-roomie/roomie/src/components/RoomieButtonLink/index.vue'

import type { Section } from './types'

defineProps({
  gradientPosition: {
    type: String as () => 'left' | 'right',
    default: 'right'
  },
  sourcePage: {
    type: String,
    required: true
  }
})

const { t, tm, rt } = useI18n()
const sections = ref<Section[]>(tm('sections'))

</script>

<script lang="ts">
export default {
  name: 'ReferralsSection'
}

</script>

<template>
  <section class="referral-banner px-lh-24 py-lh-72 md:px-[8%] lg:px-[15.5rem] grid">
    <span class=" text-14 text-carbon-300 tracking-[.05rem]">
      {{ t('sectionName') }}
    </span>

    <i18n-t
      keypath="title.fullTitle"
      class="text-32 md:max-w-[70%] max-w-full text-white"
      tag="h1">
      <span class="font-bold">{{ t('title.winUntil') }}</span>

      <span class="font-bold text-brand-600">{{ t('amount') }}</span>
    </i18n-t>

    <ol
      class="grid justify-center grid-cols-1 mt-56 text-white gap-80 md:gap-40 referral-banner__list md:grid-cols-3">
      <li
        v-for="(section, index) in sections"
        :key="index"
        class="referral-banner__list-item">
        <div class="flex flex-row items-center justify-center gap-8">
          <div class="counter" />

          <img
            :src="`${rt(section.image)}?auto=compress&h=164`"
            :alt="section.title"
            class="object-contain h-164">
        </div>

        <article class="flex flex-col items-center justify-center w-full gap-16 p-2">
          <RoomieDivider
            class="w-3/5 m-auto"
            color="carbon-light-600" />

          <h2 class="font-bold text-center text-20">
            {{ rt(section.title) }}
          </h2>

          <p class="text-center text-14 font-regular ">
            {{ rt(section.description, {amount: t('amount')}) }}
          </p>
        </article>
      </li>
    </ol>

    <RoomieButtonLink
      id="referral-button-register-now"
      data-lh-id="referral-button-register-now"
      :aria-label="t('registerNow')"
      class="mx-auto text-center mt-60"
      target-type="_blank"
      :url="`${t('registerUrl')}?utm_source=${sourcePage}&utm_medium=web`">
      {{ t('registerNow') }}
    </RoomieButtonLink>
  </section>
</template>

<i18n src="./i18n.json" lang="json" />

<style lang="scss" scoped>
.referral-banner {
  /* stylelint-disable */
  background: radial-gradient(500px 200px at top v-bind('gradientPosition'), #145E4E ,#181E1C);
  /* stylelint-enable */

  &__list {
    list-style: none;

    &-item {
      @apply  gap-24 items-center justify-center h-full;

      counter-increment: item;
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: max-content;

      .counter::before {
        @apply text-56 font-semibold text-carbon-600;

        grid-area: counter;
        flex: 0 0 auto;
        content: counter(item ) ". ";
      }
    }
  }
}
</style>
